import Vue from 'vue'
import vueRouter from 'vue-router'
const Index = () =>import('../components/index/Index.vue')
const AboutUs = () =>import('../components/about/AboutUs.vue')
const News= () => import('../components/news/News.vue')
const NewsDetail= () => import('../components/news/NewsDetail.vue')
const BusinessDomain= () => import('../components/business/BusinessDomain.vue')
const BusinessDetail = () => import( '../components/business/BusinessDetail.vue')
const SocialResponsibility = () => import( '../components/social/SocialResponsibility.vue')
const SocialResponsibilityList = () => import( '../components/social/SocialResponsibilityList.vue')
const SocialResponsibilityDetail = () => import( '../components/social/SocialResponsibilityDetail.vue')
const Recruit = () => import( '../components/recruit/RecruitIndex.vue')
const RecruitForm = () => import( '../components/recruit/RecruitForm.vue')
const Contact = () => import( '../components/contact/Contact.vue')
const ObtainEmployment = () => import( '../components/obtain/ObtainEmployment.vue')
const ObtainEmploymentDetail = () => import( '../components/obtain/ObtainEmploymentDetail.vue')
const OnlineService = () => import( '../components/service/OnlineService.vue')
const PartyBuilding = () => import( '../components/party/PartyBuilding.vue')
const PartyBuildingDetail = () => import( '../components/party/PartyBuildingDetail.vue')
const ProjectDebriefing = () => import( '../components/service/ProjectDebriefing.vue')
const Maintenance = () =>import('../components/service/Maintenance.vue')
const NewProjects = () =>import('../components/service/NewProjects.vue')
const Install = () =>import('../components/service/Install.vue')
const Capacity =() =>import('../components/service/Capacity.vue')
const Reform =() =>import('../components/service/Reform.vue')
const MaintenanceLabel = () =>import('../components/service/MaintenanceLabel.vue')
const AgentRepair = () =>import('../components/service/AgentRepair')
const RushRepair = () =>import('../components/service/RushRepair')
const Inspect = () =>import('../components/service/Inspect.vue')
const Experiment = () =>import('../components/service/Experiment.vue')

Vue.use(vueRouter)

const routes = [ {
        path:'', redirect:'/index'
    }, {
        path:'/index', component:Index,
        meta: { title: '北京华创龙达建设工程有限公司' }
    }, {
        path:'/about_us', component:AboutUs,
        meta:{ title:'关于我们-北京华创龙达建设工程有限公司' }
    }, {
        path:'/news', component:News,
        meta:{ title:'新闻中心-北京华创龙达建设工程有限公司' }
    }, {
        path:'/news_detail', component:NewsDetail
    }, {
        path:'/business_domain', component:BusinessDomain,
        meta:{ title:'业务领域-北京华创龙达建设工程有限公司' }
    }, {
        path:'/business_detail', component:BusinessDetail
    }, {
        path:'/social_responsibility', component:SocialResponsibility,
        meta:{ title:'社会责任-北京华创龙达建设工程有限公司' }
    }, {
        path:'/social_responsibility_list', component:SocialResponsibilityList,
        meta:{ title:'社会责任-北京华创龙达建设工程有限公司' }
    }, {
        path:'/social_responsibility_detail', component:SocialResponsibilityDetail
    }, {
        path:'/recruit', component:Recruit,
        meta:{ title:'企业招聘-北京华创龙达建设工程有限公司' }
    }, {
        path:'/recruit_form', component:RecruitForm,
        meta:{ title:'职位申请-北京华创龙达建设工程有限公司' }
    }, {
        path:'/contact', component:Contact,
        meta:{ title:'联系我们-北京华创龙达建设工程有限公司' }
    }, {
        path:'/obtain_employment', component:ObtainEmployment,
        meta:{ title:'就业指导-北京华创龙达建设工程有限公司' }
    }, {
        path:'/obtain_employment_detail', component:ObtainEmploymentDetail
    }, {
        path:'/online_service', component:OnlineService,
        meta:{ title:'在线服务-北京华创龙达建设工程有限公司' }
    }, {
        path:'/party_building', component:PartyBuilding,
        meta:{ title:'党的建设-北京华创龙达建设工程有限公司' }
    }, {
        path:'/party_building_detail', component:PartyBuildingDetail
    }, {
        path:'/project_debriefing', component:ProjectDebriefing,
        meta:{ title:'项目进度查询-北京华创龙达建设工程有限公司' }
    }, {
        path:'/maintenance', component:Maintenance,
        meta:{ title:'工程后期维护-北京华创龙达建设工程有限公司' }
    }, {
        path:'/new_projects', component:NewProjects,
        meta:{ title:'新项目咨询-北京华创龙达建设工程有限公司' }
    }, {
        path:'/install', component:Install,
        meta:{ title:'报装-北京华创龙达建设工程有限公司' }
    }, {
        path:'/capacity', component:Capacity,
        meta:{ title:'扩容-北京华创龙达建设工程有限公司' }
    }, {
        path:'/reform', component:Reform,
        meta:{ title:'改造（迁移）-北京华创龙达建设工程有限公司' }
    }, {
        path:'/maintenance_label', component:MaintenanceLabel,
        meta:{ title:'工程后期维护-北京华创龙达建设工程有限公司' }
    }, {
        path:'/agent_repair', component:AgentRepair,
        meta:{ title:'代维-工程后期维护-北京华创龙达建设工程有限公司' }
    }, {
        path:'/rush_repair', component:RushRepair,
        meta:{ title:'抢修-工程后期维护-北京华创龙达建设工程有限公司' }
    }, {
        path:'/inspect', component:Inspect,
        meta:{ title:'巡检-工程后期维护-北京华创龙达建设工程有限公司' }
    }, {
        path: '/register', name: 'register', component: () => import('../views/Register.vue') 
    }, {
        path: '/login', name: 'login', component: () => import('../views/Login.vue') 
    }, {
        path:'/experiment', component:Experiment,
        meta:{ title:'实验-工程后期维护-北京华创龙达建设工程有限公司' }
    }
];
 
const router = new vueRouter({
    routes,
    mode:'hash'
});
 
export default router;
