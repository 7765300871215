import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import '@/assets/css/global.css';
import '@/assets/css/contactCall.css';
import echarts from 'echarts'
import router from './router/app'
import Vuex from 'vuex'
import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
Vue.prototype.$axios=axios
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Vuex)
Vue.use(ViewUI)

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

const store = new Vuex.Store({
  state:{
    token:'huachuanglongda2021',
    userinfo:sessionStorage.getItem("userinfo")
  }
})

new Vue({
  router,
  store: store,
  render: h => h(App),
}).$mount('#app')
 
